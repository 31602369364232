import {Injectable, OnDestroy} from '@angular/core';

import {
  FirebaseService,
  MobileStateResponse,
  MappingDetail,
  RouteInfoInterface, MobileStateService
} from "@brightside-web/desktop/data-access/shared";
import {Subscription} from "rxjs";
import {BsAuthService, BsHubService} from "@brightside-web/desktop/data-access/core-services";
import {HubCapsule} from "@micro-core/message-bus";

@Injectable({
  providedIn: 'root'
})
export class CampaignRoutingService implements OnDestroy {
  nativeToDesktopMapping: any;
  mobileState: MobileStateResponse;
  currentlyWorking = false;
  sub = new Subscription();

  constructor(
    protected analytics: FirebaseService,
    private mobileStateService: MobileStateService,
    private bsHubService: BsHubService
  ) {

    const listener = (data: HubCapsule<any, any>) => {
      switch (data.payload.event) {
        case 'signedIn':
          this.mobileStateService.get().subscribe(
            ms => {
              if (ms) {
                this.mobileState = ms;
              }
            }
          );
          break;
      }
    }
    this.bsHubService.listen('bsAuth', listener);
  }


  campaignInMobileState(campaignKey: string) : MappingDetail | null {
    if (this.mobileState?.campaigns && campaignKey in this.mobileState.campaigns) {
      const ctaPath = this.mobileState.campaigns[campaignKey].path || '';
      return { path: ctaPath };
    }
    return null;
  }

  routeMappingInRemoteConfig(routeToString: string) : MappingDetail | null {
    this.nativeToDesktopMapping = this.nativeToDesktopMapping ?? this.analytics.remoteConfigMapping();
    if (routeToString in this.nativeToDesktopMapping) {
      return this.nativeToDesktopMapping[routeToString] || {path: ''};
    }
    return null;
  }

  /**
   * checks link against the campaigns in mobile state, then looks at a mapping property in Firebase Remote Config
   * @param campaignLink
   * @param errorEventName
   */
  navigateToCampaign(campaignLink: string, errorEventName?: string) : RouteInfoInterface | undefined {

    if (campaignLink && !this.currentlyWorking) {
      this.currentlyWorking = true;
      setTimeout(() => {this.currentlyWorking = false;}, 1000);
      // first we check if the gobrightside:// path matches to a campaign in mobile state
      const breakRoute = campaignLink.replace('gobrightside://', '').split('?');
      const msCampaignPath = this.campaignInMobileState(breakRoute[0]);

      if (msCampaignPath) {
        return {mappingDetail: msCampaignPath, queryString: breakRoute[1]};
      } else {
        // if we dont match to a campaign then we will try to match to a property in Remote Config for the mapping
        const routeMapping = this.routeMappingInRemoteConfig(breakRoute[0]);
        if (routeMapping) {

          if(routeMapping.path === 'loans') {
            routeMapping.path = 'loans/intro';
          }

          return {mappingDetail: routeMapping, queryString: breakRoute[1]};
        } else {
          if (errorEventName) this.analytics.logEvent(errorEventName);
          return undefined;
        }
      }
    } else {
      return undefined;
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
