import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Environment} from '@brightside-web/micro/core/environment';
import {
  DesktopCard,
  DesktopCardCaseType,
  DesktopCardFooter,
  DesktopCardSegment,
  DesktopCardType,
  DesktopSegmentType,
  FirebaseService,
  InternalLinkRoutingService, KnownFeatureFlags,
  ModalModel,
  ModalService,
  RemoteConfig_UnlockedCaseTypes
} from '@brightside-web/desktop/data-access/shared';
import {Router} from '@angular/router';
import {ToastService} from '@brightside/brightside-ui-services';
// tslint:disable-next-line:nx-enforce-module-boundaries
import {CardService} from '@brightside-web/desktop/data-access/cards';

import { BsCacheService } from "@brightside-web/desktop/data-access/core-services";
import {FeatureFlagService} from "@brightside-web/desktop/data-access/core-services";
import {LoanFundingType, LoanState} from "@brightside-web/desktop/data-access/loans";

export enum CardDisplayType {
  DEFAULT = 'Default',
  DEFAULT_INFO = 'DefaultInfo',
  ACTIVE = 'Active',
  ACTIVE_DEFAULT = 'ActiveDefault',
  ACTIVE_LOCKED = 'ActiveLocked',
}

@Component({
  selector: 'brightside-web-desktop-card',
  templateUrl: './desktop-card.component.html',
  styleUrls: ['./desktop-card.component.scss'],
})
export class DesktopCardComponent implements OnInit {
  _card: DesktopCard;
  cardBannerColor: string;
  infoSegments: DesktopCardSegment[] = [];
  defaultSegments: DesktopCardSegment[] = [];
  ctaSegments: DesktopCardSegment[] = [];
  errorFooter: DesktopCardFooter;

  @Input() set card(value: DesktopCard) {
    this._card = value;

    if (value.footer && value.footer.style?.toLowerCase() === 'error') {
      this.cardBannerColor = 'var(--red-10)';
      this.errorFooter = value.footer;
    }

    //ToDo: need handling for the other types, example cashflow
    if (value.case_type === DesktopCardCaseType.CASH_FLOW && value.segments) {
      this.defaultSegments.push(value.segments[0]);
      return;
    }

    if ((value.case_type === DesktopCardCaseType.MPAP || value.case_type === DesktopCardCaseType.HELPING_HANDS) && value.segments) {
      this.ctaSegments.push(value.segments[0]);
    }

    //Need to check promo cards and ensure they have a BG image
    if (value.type === DesktopCardType.DEFAULT) {
      const defaultBgName = value.closeable ? 'card-bg' : 'credit-card-bg';

      value.graphic = `assets/${value.graphic || defaultBgName}.svg`;
    }

    value.segments?.forEach((segment) => {
      switch (segment.type.toLowerCase()) {
        case DesktopSegmentType.INFO.toLowerCase():
          this.infoSegments.push(segment);
          break;
        case DesktopSegmentType.DEFAULT.toLowerCase():
          this.defaultSegments.push(segment);
          break;
      }
    });
  }
  get card() {
    return this._card;
  }

  @Output() clicked = new EventEmitter();
  @Output() closed = new EventEmitter();

  displayType: CardDisplayType = CardDisplayType.DEFAULT_INFO;
  displayTypes = CardDisplayType;

  iconChevronRight = 'chevron_right';

  hideCard = false;
  showVideoModal = false;

  unlockedCaseTypes: string[];
  cdnBase: any;
  bannerModalVisible = false;
  modalModel: ModalModel;

  constructor(
    private env: Environment,
    private router: Router,
    private toastService: ToastService,
    private modalSvc: ModalService,
    private analytics: FirebaseService,
    private internalLinkRouting: InternalLinkRoutingService,
    private featureFlagSvc: FeatureFlagService,
    private bsCacheService: BsCacheService
  ) {
    this.unlockedCaseTypes = this.analytics.getValueAsString(RemoteConfig_UnlockedCaseTypes).split(',');
    this.cdnBase = this.env.cdnUrl;
  }

  ngOnInit(): void {
    this.determineDisplayType();
  }

  private determineDisplayType() {
    const determineNonProductDisplayType = () => {};
    const determineActiveDisplayType = () => {
      if (this.card.case_type === DesktopCardCaseType.SPENDING) {
        this.displayType = this.displayTypes.ACTIVE;
        return;
      }

      if (this.card.type === DesktopCardType.DEFAULT) {
        this.displayType = this.displayTypes.ACTIVE_DEFAULT;
        return;
      }

      // In environment vars we can manage the unlocked case types - If it's in the list, it's unlocked
      if (this.unlockedCaseTypes.indexOf(this.card.case_type || '') === -1) {
        // Locked until code is added
        this.displayType = this.displayTypes.ACTIVE_LOCKED;
        return;
      }

      this.displayType = this.displayTypes.ACTIVE;
    };

    this.displayType = this.displayTypes.DEFAULT;

    // See if it's a non-product card
    if (!this.card.case_type) {
      determineNonProductDisplayType();
      return;
    } else {
      // See if it's an active card
      determineActiveDisplayType();
      return;
    }
  }

  //TODO should card events be processed in this component or merely passed all the way back up to the feed component?
  //TODO time to create a navigation/routing service to abstract link types so these sort of paths are reusable everywhere
  //(my vote is to handle them here since they are card-specific events)
  ctaHandler(event: Event) {

    event.stopPropagation();
    if (this.card && this.card.analytics && this.card.analytics.tapped) {
      this.analytics.logEvent(this.card.analytics?.tapped);
    }
    if (this.card.ctaPath?.startsWith('video:')) {
      this.showVideoModal = true;
    } else if (this.card.ctaPath?.startsWith('toast:')) {
      this.toastService.info(CardService.getToastMessage(this.card.ctaPath.slice('toast:'.length)));
    } else if (this.card.ctaPath?.startsWith('gobrightside:')) {
      this.internalLinkRouting.routeToLink(this.card.ctaPath);
    } else if(
      (this.card.case_type === DesktopCardCaseType.MPAP || this.card.case_type === DesktopCardCaseType.HELPING_HANDS) && this.card.segments
    ) {
      this.router.navigateByUrl(this.card.segments[0].ctaPath);
    } else if (this.card.ctaPath) {
      if (this.card.case_type) {
        this.bsCacheService.setItem('activeCaseType', this.card.case_type);
        const loanCaseTypes = ['Emergency_Cash', 'Credit_Card_Refi'];
        if (this.card.vendor) {
          this.bsCacheService.setItem('loanVendorFromCard', this.card.vendor);
          this.card.ctaPath = 'accounts/loans';
        }
      }

      this.router.navigateByUrl(this.card.ctaPath);
    }

    this.completeCardHandler();
  }


  clickHandler() {
    if (this.card && this.card.analytics && this.card.analytics.tapped) {
      this.analytics.logEvent(this.card.analytics?.tapped);
    }
    if (this.card && this.card.ctaPath && this.card.ctaPath.indexOf('gobrightside:') !== -1) {
      this.internalLinkRouting.routeToLink(this.card.ctaPath);
    }
    this.clicked.emit(this.card);
  }

  completeCardHandler() {
    if (this.card.closeable) {
      this.hideCard = true;

      this.closed.emit(this.card.key || '');
    }
  }

  bannerHandler(event: MouseEvent) {
    event.stopPropagation();
    const infoSegment = this.card.segments?.find((seg) => seg.type === DesktopSegmentType.INFO);
    if (infoSegment) {
      if (infoSegment.ctaPath?.startsWith('modal:')) {
        this.modalModel = this.modalSvc.getModalModel(infoSegment.ctaPath.slice('modal:'.length));
        this.bannerModalVisible = true;
      }
    }
  }

  errorStateClickHandler(event: MouseEvent) {
    if (this.errorFooter && this.errorFooter.ctaPath) this.router.navigateByUrl(this.errorFooter.ctaPath);
  }
}
