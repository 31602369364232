import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'brightside-web-sign-up-prompt',
  templateUrl: './sign-up-prompt.component.html',
  styleUrls: ['./sign-up-prompt.component.scss']
})
export class SignUpPromptComponent {
  @Input() visible: any;
  @Output() dismiss = new EventEmitter();
  @Output() cta = new EventEmitter();

  constructor() { }



  dismissClick(){
    this.dismiss.emit();
  }

  ctaClick(){
    this.cta.emit();
  }
}
