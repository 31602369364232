<bw-action-article [brightsideWebFb]="{ event_name: 'kyc_intro' }">
  <div header-actions>
    <ui-nav-exit-back
      [trackingDetail]="{ event_name: 'kyc_intro' }"
      (exitActionHandler)="goHome()"
    ></ui-nav-exit-back>
  </div>
  <div content-section>
    <div class="mb-sm">
      <img src="../assets/kyc-cameras.png" />
    </div>
    <div class="copy-wrapper">
      <typography title-medium class="mb-sm" color="var(--primary-text)">
        <div [innerHTML]="'KYC_INTRO_TITLE' | translate"></div>
      </typography>
      <typography text-medium-regular class="mb-md" color="var(--secondary-text)">
        <div [innerHTML]="'KYC_INTRO_DESC' | translate"></div>
      </typography>
    </div>
  </div>

  <div footer-actions>
    <div class="flex-row row-reverse">
      <button
        bw-button
        type="submit"
        (click)="showSocureModal()"
        [brightsideWebFb]="{ event_name: 'kyc_intro' }"
      >
        {{ 'BUTTON_CONTINUE' | translate }}
      </button>
    </div>
  </div>

</bw-action-article>



<bw-modal *ngIf="showKycModal"
          [showCloseButton]="true"
          (closeModal)="showKycModal = false"
          modalWidth="large" [brightsideWebFb]="{event_name: 'kyc_intro_modal'}">
  <div class="body">
    <bw-spinner *ngIf="showSpinner"></bw-spinner>
    <div id="socureDiv"></div>
  </div>
</bw-modal>

<bw-modal *ngIf="showVerifyingModal"
          modalWidth="medium">
  <div class="body center mb-sm" >
    <bw-spinner></bw-spinner>
    <typography>{{ 'VERIFICATION_VALIDATING' | translate }}</typography>
  </div>
</bw-modal>
