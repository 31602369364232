import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { combineLatest, from, Observable, of, Subscription } from 'rxjs';
import {
  BsAuthService,
  BsCacheService
} from '@brightside-web/desktop/data-access/core-services';
import { CreatePasswordComponent } from '@brightside/brightside-ui';
import { map } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RegistrationService } from '@brightside-web/desktop/data-access/onboarding';
import { Router } from '@angular/router';
import {
  AuthService,
  BasicComponentConfig,
  CognitoErrorResponse,
  FirebaseService,
  CompanyService, NotificationMessageTypeEnum, NotificationMessageChannelEnum, NotificationService
} from '@brightside-web/desktop/data-access/shared';
import { ToastService } from '@brightside/brightside-ui-services';

@Component({
  selector: 'brightside-web-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit, AfterViewInit, OnDestroy {

  sub = new Subscription();
  showReset = true;
  showConfirm: boolean;
  processing: boolean;
  showFaModal: boolean;
  @ViewChild(CreatePasswordComponent) createPasswordComponent: CreatePasswordComponent;
  resetPasswordFormIsValid$: Observable<boolean>;
  confirmForm:FormGroup;
  error: string;
  phone:string;
  isRegistration = false;
  company:string;

  config:BasicComponentConfig = new BasicComponentConfig({ fbPage: "forgot_password", fbCategory: "reset_password", fbEventName: "reset_password"})


  constructor(
    private toastsvc:ToastService,
    private router:Router,
    private regSvc:RegistrationService,
    protected analytics: FirebaseService,
    private auth:AuthService,
    private companyService: CompanyService,
    private notificationSvc: NotificationService,
    private bsAuthService: BsAuthService,
    private bsCacheService: BsCacheService
    ) {
    this.bsCacheService.getItem('phoneNumber').then(
      phoneNumber => {
        this.phone = phoneNumber;
        this.initForm();
      }
    )
  }

  ngOnInit() {
  }

  initForm() {
    this.confirmForm = new FormGroup({
      code: new FormControl('', [
        Validators.required,
        Validators.maxLength(6),
        Validators.minLength(6),
        Validators.pattern('\\d{6}')
      ])
    });
    // this.sub.add(from(this.bsAuthService.currentAuthenticatedUser()).subscribe(_ => {
    // }, ()=>{
    //   this.isRegistration = true;
    // }));
    this.sub.add(
      this.companyService.company.subscribe(
        value => {
          this.company = value;
        }
      )
    );
  }

  requestCode(){
    this.processing = true;
   // this.sub.add(from(this.bsAuthService.resetPassword({username: this.phone})).subscribe(()=>{
   //   this.processing = false;
   //   this.showReset = false;
   //    this.showConfirm = true;
   //  }));
  }

  confirmSubmit(){
    this.processing = true;
    // this.sub.add(from(this.bsAuthService.confirmResetPassword({
    //   username: this.phone,
    //   confirmationCode: this.confirmForm.controls['code'].value,
    //   newPassword: this.createPasswordComponent.createPassword.controls['password'].value
    // })).subscribe(_=>{
    //     this.sub.add(from(this.bsAuthService.signIn(this.phone, this.createPasswordComponent.createPassword.controls['password'].value)).subscribe(()=>{
    //       this.analytics.logEvent('sign_in_completed', { type: 'forgot password' });
    //       RegistrationService.clearOnboardingCache();
    //       this.notificationSvc.sendMessage(NotificationMessageTypeEnum.CHANGE_EMAIL, NotificationMessageChannelEnum.EMAIL);
    //       this.toastsvc.success('Your password has been updated');
    //       from(this.router.navigate( [{outlets: {modal: null}}])).subscribe(()=>this.router.navigate(['/home']));
    //     }))
    // },(fpsErr:CognitoErrorResponse)=>{
    //     this.processing = false;
    //     this.error = fpsErr.message;
    // }));
  }

  ngAfterViewInit(): void {
    const statusIsTrue = map(status => status === 'VALID');

    const pwStatus = this.createPasswordComponent.createPassword.statusChanges.pipe(
      statusIsTrue
    );

    this.resetPasswordFormIsValid$ = combineLatest([pwStatus]).pipe(
      map((statuses: boolean[]) => statuses.every(status => status))
    );

  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  changeNumber() {
    this.auth.password = this.createPasswordComponent.createPassword.controls['password'].value;
    this.router.navigate(['/registration', 'change-number']);
  }
}
