import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import {from, Observable, of, switchMap} from 'rxjs';
import {
  BsCacheService,
  BsCacheService as Cache
} from '@brightside-web/desktop/data-access/core-services';
import {catchError, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class VerifyDobGuard implements CanActivate {
  constructor(
    protected router: Router,
    private bsCacheService: BsCacheService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return from(this.bsCacheService.getItem('lastName')).pipe(
      switchMap(lastname => {
        if (lastname) {
          return from(this.bsCacheService.getItem('last4ssn')).pipe(
            map(last4 => {
              if (last4) {
                return true;
              } else {
                return this.router.createUrlTree(['/']);
              }
            }),
            catchError(() => of(this.router.createUrlTree(['/'])))
          );
        } else {
          // Navigate and return UrlTree for better handling
          return of(this.router.createUrlTree(['/']));
        }
      }),
      catchError(() => of(this.router.createUrlTree(['/'])))
    );
  }

}
