import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  Route,
  UrlSegment
} from '@angular/router';
import {
  BsCacheService
} from '@brightside-web/desktop/data-access/core-services';
import {Observable, forkJoin, of, from} from 'rxjs';
import { map, catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ResetPwGuard implements CanActivate {
  constructor(
    private bsCacheService: BsCacheService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.checkAccess();
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean | UrlTree> {
    return this.checkAccess();
  }

  private checkAccess(): Observable<boolean | UrlTree> {
    const keys = ['phoneNumber', 'recoveryToken', 'email', 'emailVerified', 'phoneVerified'];
    const cacheObservables = keys.map(key => from(this.bsCacheService.getItem(key)));

    return forkJoin(cacheObservables).pipe(
      map(results => {
        const allValid = results.every(result => result !== null);
        return allValid ? true : this.router.createUrlTree(['registration', 'create-account']);
      }),
      catchError(error => {
        console.error('Error fetching cache items:', error);
        return of(this.router.createUrlTree(['registration', 'create-account']));
      })
    );
  }
}
