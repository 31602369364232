<div [ngStyle]="{ display: showReset ? 'inherit' : 'none' }">
  <bw-action-article>
    <ui-nav-exit-back header-actions [trackingDetail]="{ event_name: 'reset_password_dismissed' }"></ui-nav-exit-back>

    <typography header-title title-medium>{{ 'RESET_PASSWORD' | translate }}</typography>
    <div content-section style="max-width: 360px">
      <bw-paragraph>{{ 'RESET_PASSWORD_DESC' | translate }}</bw-paragraph>
      <bw-create-password
        label="New Password"
        fcName="password"
        [autoFocus]="true"
        (keyup.enter)="requestCode()"
        [characterCountRule]="'PASSWORD_REQ_LENGTH' | translate"
        [capitalLetterRule]="'PASSWORD_REQ_CAPS' | translate"
        [numberRule]="'PASSWORD_REQ_NUMBER' | translate"
      ></bw-create-password>
    </div>
    <div footer-actions class="flex-row row-reverse">
      <!-- http://codelyzer.com/rules/templates-no-negated-async/ the form does not work right unless the conditional is implemented this way -->
      <button bw-button (click)="requestCode()" [processing]="processing" [disabled]="!(resetPasswordFormIsValid$ | async)">
        {{ 'BUTTON_CONTINUE' | translate }}
      </button>
    </div>
  </bw-action-article>
</div>

<brightside-web-call-fa
  *ngIf="showFaModal"
  (dismiss)="showFaModal = false"
  [title]="'CONTACT_ASSISTANT' | translate"
  [ctaText]="'DISMISS' | translate"
  [company]="company"
></brightside-web-call-fa>

<div *ngIf="showConfirm">
  <!-- TODO WB-1112 can this forgot pw confirm be combined with the signup confirm -->
  <form [formGroup]="confirmForm" (ngSubmit)="confirmSubmit()">
    <bw-action-article>
      <ui-nav-exit-back
        header-actions
        [trackingDetail]="{ event_name: 'reset_password_dismissed' }"
        (exitActionHandler)="showConfirm = false; showReset = true"
      ></ui-nav-exit-back>

      <typography title-medium header-title>Confirm device</typography>
      <div content-section style="max-width: 360px">
        <bw-paragraph [textStyle]="'Body1'">{{
          'UPDATE_PASSWORD_CODE_HEADING' | translate: { maskedPhoneNumber: phone | phone: true }
        }}</bw-paragraph>
        <bw-paragraph [textStyle]="'Body1'" *ngIf="!isRegistration"
          >{{ 'NOT_YOUR_NUMBER' | translate }}&nbsp
          <a bw-anchor anchorUnderline="true" (click)="showFaModal = true"> {{ 'CONTACT_ASSISTANT_FOR_HELP' | translate }}</a>
        </bw-paragraph>
        <bw-input
          formControlName="code"
          label="Code"
          inputMask="000000"
          minlength="6"
          maxlength="6"
          [inputError]="!!error && confirmForm.controls['code']?.touched"
          [inputErrorText]="error"
          verticalSpace="mb-md"
          [autoFocus]="true"
        ></bw-input>
        <div [ngClass]="{ center: isRegistration }">
          <a bw-anchor anchorStyle="text-small-regular" (click)="requestCode()">
            {{ 'RESEND_CODE' | translate }}
          </a>
          <span *ngIf="isRegistration">
            &nbsp;|&nbsp;
            <a bw-anchor anchorStyle="text-small-regular" (click)="changeNumber()"> {{ 'CHANGE_NUMBER' | translate }} </a>
          </span>
        </div>
      </div>
      <div footer-actions class="flex-row row-reverse">
        <button bw-button type="submit" [processing]="processing" [disabled]="confirmForm.invalid">
          {{ 'BUTTON_CONTINUE' | translate }}
        </button>
      </div>
    </bw-action-article>
  </form>
</div>
