import { Injectable } from '@angular/core';
import {
  ApiCacheService,
  ApiResponse,
  DesktopCard,
  PayrollAccountResponse,
  PayrollScheduleResponse,
} from '@brightside-web/desktop/data-access/shared';
import { from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  AwsApiWrapperService,
  BsCacheService,
  BsHubService,
} from '@brightside-web/desktop/data-access/core-services';
import { HubCapsule } from 'aws-amplify/utils';

const useRealData = true;

@Injectable({
  providedIn: 'root',
})
export class LoansService {
  constructor(
    private apiCache: ApiCacheService,
    private awsApiWrapperService: AwsApiWrapperService,
    private bsCacheService: BsCacheService,
    private bsHubService: BsHubService,) {
    this.bsHubService.listen('LoansChannel', (data: HubCapsule<any, any>) => {
      if (data.payload.event === 'refreshStatus') {
        this.getPayrollAccountStatus(true).subscribe((response) =>
          this.bsHubService.dispatch('LoansChannel', { event: 'accountRefresh', data: response })
        );
      }
    });
  }

  // getLoanState(cardType?: string): LoanState {
  //   if (cardType) {
  //     return Cache.getItem(`loanState${cardType}`);
  //   } else {
  //     return Cache.getItem(`loanState${Cache.getItem('activeCaseType')}`);
  //   }
  // }

  async groomCardData(startingData: DesktopCard): Promise<DesktopCard> {
    // @ts-ignore
    const pathPieces = startingData.ctaPath?.split(';') || [];
    const keyvalpairs = pathPieces.slice(1);
    const kvmap = keyvalpairs.reduce((p, c) => {
      const keyval: string[] = c.split('=');
      p[keyval[0]] = keyval[1];
      return p;
    }, {} as Record<string, string>);
    const loanState: LoanState = {
      type: kvmap['type'] as LoanFundingType,
      vendor: kvmap['vendor'],
      consent: kvmap['consent']?.split(','),
      cardType: kvmap['cardtype']
    };


    this.bsCacheService.setItem(`loanState${kvmap['cardtype']}`, loanState);

    startingData.vendor = loanState.vendor;
    startingData.ctaPath = '/loans/landing';
    return startingData;
  }

  // MW payroll-loan.service getLoanRedirectUrl
  /**
   * does not throw API errors, it maps them to a LoanLinkResponse with errorMessage populated
   * @param vendor
   */
  getLoanLink(vendor: string): Observable<LoanLinkResponse> {
    return this.apiCache.get<LoanLinkResponse>('api-mobile', `/loans/payrollbased?provider=${vendor}`).pipe(
      // apiCache does not cache an error response (how could we both cache it and return cached response as an error)
      catchError((err) => of(err.response.data))
    );
  }

  // MW payroll-loan.service createPayrollCustodialAccount
  // createPayrollCustodialAccount(): Observable<ApiResponse> {
  //   this.apiCache.refreshItem(`/loans/payrollbased?provider=${this.getLoanState().vendor}`);
  //   this.apiCache.refreshItem(`/payroll/account`);
  //   return from(API.post('api-mobile', '/payroll', { headers: { 'Content-Type': 'application/json' } }));
  // }

  // MW payroll-loan.service fetchPayrollCustodialAccount
  getPayrollAccountStatus(force = false): Observable<string> {
    if (force) {
      this.apiCache.refreshItem('/payroll/account');
    }
    return this.apiCache.get<PayrollAccountResponse>('api-mobile', '/payroll/account').pipe(map((response) => response.status));
  }

  //MW finsol shared payroll.service getPayrollSchedule
  getPayrollSchedule(): Observable<PayrollScheduleResponse> {
    //TODO we have the same call in another service, should use one call one service
    if (useRealData) {
      return this.apiCache.get<PayrollScheduleResponse>('api-mobile', '/payroll/schedule');
    }
    return of({
    "schedule": [
        "2023-03-03",
        "2023-03-17",
        "2023-03-31",
        "2023-04-14",
        "2023-04-28",
        "2023-05-12",
        "2023-05-26",
        "2023-06-09",
        "2023-06-23",
        "2023-07-07",
        "2023-07-21",
        "2023-08-04",
        "2023-08-18",
        "2023-09-01",
        "2023-09-15",
        "2023-09-29",
        "2023-10-13",
        "2023-10-27",
        "2023-11-10",
        "2023-11-24",
        "2023-12-08",
        "2023-12-22",
        "2024-01-05",
        "2024-01-19",
        "2024-02-02",
        "2024-02-16",
        "2024-03-01",
        "2024-03-15",
        "2024-03-29",
        "2024-04-12",
        "2024-04-26",
        "2024-05-10",
        "2024-05-24",
        "2024-06-07",
        "2024-06-21",
        "2024-07-05",
        "2024-07-19",
        "2024-08-02",
        "2024-08-16",
        "2024-08-30",
        "2024-09-13",
        "2024-09-27",
        "2024-10-11",
        "2024-10-25",
        "2024-11-08",
        "2024-11-22",
        "2024-12-06",
        "2024-12-20",
        "2025-01-03"
    ]
})
  }
}
export enum LoanFundingType {
  ACH = 'ach' /** we do not support ach loans at this time **/,
  PAYROLL = 'payroll',
}
export interface LoanState {
  type: LoanFundingType;
  vendor: string;
  consent: string[];
  cardType?: string;
}
export interface LoanLinkResponse {
  link?: string;
  errorMessage?: string;
}

export interface LoanObject {
  [key: string]: any;
}
