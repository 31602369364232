import { Component, OnInit } from '@angular/core';
import {
  SimpleGlobalModalInterface,
  SimpleGlobalModalService
} from "@brightside-web/desktop/data-access/core-services";
import {Observable, Subject} from "rxjs";
import {Router} from "@angular/router";

@Component({
  selector: 'brightside-web-simple-global-modal',
  templateUrl: './simple-global-modal.component.html',
  styleUrls: ['./simple-global-modal.component.scss']
})
export class SimpleGlobalModalComponent implements OnInit {

  currentModal$: Observable<SimpleGlobalModalInterface | null>;   
  private _modalLoadingCta$ = new Subject<boolean>();
  public readonly modalLoadingCta$ : Observable<boolean> = this._modalLoadingCta$.asObservable();  

  constructor(
    private sGlobalModalSvc: SimpleGlobalModalService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.currentModal$ = this.sGlobalModalSvc.modalObject;
    this._modalLoadingCta$.next(false);
  }

  handleCtaClick(ctaAction: () => void, loadingAfterCta: boolean = false) {    
    if (!loadingAfterCta) this.sGlobalModalSvc.clearModal();
    else this._modalLoadingCta$.next(true);
    
    if (typeof ctaAction === 'function') {
      ctaAction();
    } else {
      this.router.navigate(['home']);
    }
  }

  closeModal(modal?:SimpleGlobalModalInterface) {
    if (modal?.closeRoute) {
      this.router.navigate(modal?.closeRoute);
    } else if (modal?.closeAction && typeof modal?.closeAction === 'function') {
      modal.closeAction();
    } else {
      this.sGlobalModalSvc.clearModal();
    }
  }
}
