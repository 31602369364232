<bw-action-article>
  <ui-nav-exit-back header-actions [trackingDetail]="{ event_name: 'verify_user_dismissed' }"></ui-nav-exit-back>

  <typography title-medium header-title>{{ 'VERIFY_USER_TITLE' | translate }}</typography>
  <div content-section [brightsideWebFb]="{ event_name: 'verify_user_shown' }" style="max-width: 360px">
    <bw-paragraph>{{ 'VERIFY_USER_DESC' | translate }}</bw-paragraph>
    <form [formGroup]="verifyForm" (ngSubmit)="submit()">
      <bw-input
        [autoFocus]="true"
        [label]="verifyIdentityControls.lastName.labelKey | translate"
        placeholder=""
        formControlName="lastName"
        [inputError]="verifyForm.controls['lastName']?.invalid && verifyForm.controls['lastName']?.touched"
        [inputErrorText]="verifyIdentityControls.lastName.errorTextKey | translate"
        minlength="2"
      >
      </bw-input>
      <bw-input-group
        [label]="verifyIdentityControls.last4ssn.labelKey | translate"
        [inputError]="verifyForm.controls['last4ssn']?.invalid && verifyForm.controls['last4ssn']?.touched"
        [inputErrorText]="verifyIdentityControls.last4ssn.errorTextKey | translate"
      >
        <input
          type="text"
          mask="XXXX"
          [hiddenInput]="true"
          formControlName="last4ssn"
          minlength="4"
          maxlength="4"
          pattern="\d{4}"
          [brightsideWebFb]="{ event_name: 'verify_user_tapped', action: 'click' }"
        />
      </bw-input-group>
      <bw-input
        [label]="verifyIdentityControls.dateOfBirth.labelKey | translate"
        type="{{ verifyIdentityControls.dateOfBirth.type }}"
        placeholder=""
        formControlName="dateOfBirth"
        [inputError]="verifyForm.controls['dateOfBirth']?.invalid && verifyForm.controls['dateOfBirth']?.touched"
        [inputErrorText]="verifyIdentityControls.dateOfBirth.errorTextKey | translate"
        (keyup.enter)="submit()"
      >
      </bw-input>
    </form>
    <a bw-anchor anchorStyle="text-small-regular" (click)="showFaModal = true">{{ 'CONTACT_ASSISTANT_FOR_HELP' | translate }}</a>
  </div>
  <div footer-actions class="flex-row row-reverse">
    <button
      bw-button
      [processing]="processing"
      [disabled]="verifyForm.invalid"
      (click)="submit()"
      [brightsideWebFb]="{ event_name: 'verify_user_tapped', action: 'click' }"
    >
      {{ 'BUTTON_CONTINUE' | translate }}
    </button>
  </div>
</bw-action-article>
<brightside-web-call-fa
  *ngIf="showFaModal"
  (dismiss)="showFaModal = false"
  [title]="'CONTACT_ASSISTANT' | translate"
  [ctaText]="'DISMISS' | translate"
  [company]="company"
></brightside-web-call-fa>
<brightside-web-hr-modal
  [brightsideWebFb]="{ event_name: 'error_shown', custom: { type: 'verify_user' } }"
  [title]="'Brightside'"
  [prompt]="'ERROR_IDENTITY_RETRY' | translate"
  [ctaText]="'TRY_AGAIN' | translate"
  (dismissed)="showHrModal = false"
  *ngIf="showHrModal"
></brightside-web-hr-modal>
<brightside-web-sign-up-prompt
  [visible]="registerModal"
  (dismiss)="registerModal = false"
  (cta)="register()"
></brightside-web-sign-up-prompt>

<bw-modal *ngIf="showMaxAttemptsModal">
  <typography title-medium color="var(--gray-70)">
    {{ 'VERIFY_USER_MAX_ATTEMPTS_TITLE' | translate }}
  </typography>
  <section class="body">
    <bw-paragraph [textStyle]="'Body2'" color="var(--gray-50)">
      {{ 'VERIFY_USER_MAX_ATTEMPTS_DESC' | translate }}
    </bw-paragraph>
  </section>
  <footer class="footer">
    <div class="flex-row row-reverse">
      <button bw-button (click)="closeErrorModal()">
        {{ 'CLOSE' | translate }}
      </button>
    </div>
  </footer>
</bw-modal>
