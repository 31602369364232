import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RegistrationService } from '@brightside-web/desktop/data-access/onboarding';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BsAuthService,
  BsCacheService
} from "@brightside-web/desktop/data-access/core-services";
import { AxiosError } from 'axios';
import { ApiResponse, BasicComponentConfig } from '@brightside-web/desktop/data-access/shared';
import { from, Subscription } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'brightside-web-verify-dob',
  templateUrl: './verify-dob.component.html',
  styleUrls: ['./verify-dob.component.css']
})
export class VerifyDobComponent implements OnInit, OnDestroy {
  verifyForm:FormGroup;
  showHrModal: boolean;
  processing: boolean;
  sub = new Subscription();
  private isRegistration: boolean;
  registerModal: boolean;

  config: BasicComponentConfig = new BasicComponentConfig({ fbPage: "verify_dob", fbCategory: "registration", fbEventName: "verify_dob"});

  constructor(
    private regSvc:RegistrationService,
    private router:Router,
    private route:ActivatedRoute,
    private bsAuthService: BsAuthService,
    private bsCacheService: BsCacheService) {
    this.initializeForm();
  }

  initializeForm() {
    this.verifyForm = new FormGroup({
      dateOfBirth: new FormControl('', [
        Validators.required,
        Validators.pattern('(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\\d\\d'),
        Validators.maxLength(10),
        Validators.minLength(10)
      ]),
      lastName: new FormControl(''),
      last4ssn: new FormControl('')
    });
  }

  ngOnInit() {
    this.loadInitialData();
  }

  loadInitialData() {
    this.bsCacheService.getItem('lastName').then(lastName => {
      (this.verifyForm.get('lastName') as FormControl).setValue(lastName);
    });
    this.bsCacheService.getItem('last4ssn').then(last4ssn => {
      (this.verifyForm.get('last4ssn') as FormControl).setValue(last4ssn);
    });

    // this.bsAuthService.currentAuthenticatedUser().then(user => {
    //   // handle authenticated user
    // }).catch(() => {
    //   this.isRegistration = true;
    // });
  }
  submit() {
    this.processing = true;
    //https://sourcegraph.insidebrightside.com/android/-/blob/BrightSide/app/src/main/java/com/androidapp/gobrightside/mobile/loginregister/view/VerifyIdentityActivity.java#L108:39
    this.sub.add(this.regSvc.forgotPasswordPrecheck({last_four_ssn:this.verifyForm.controls['last4ssn'].value,
      last_name:this.verifyForm.controls['lastName'].value, date_of_birth:moment(this.verifyForm.controls['dateOfBirth'].value, 'MM/DD/YYYY').format('YYYY-MM-DD')}, this.isRegistration).subscribe(resp=>{
        this.bsCacheService.setItem('phoneNumber', resp.phoneNumber );
        this.bsCacheService.setItem('recoveryToken', resp.recoveryToken);
        this.router.navigate(['../','reset-password'], {relativeTo:this.route})
      },
      (err: AxiosError) => {
        this.processing = false;
        const fallbackErr = {statusCode:0, message:''} as ApiResponse;
        this.handleResponse(
          err.response && err.response.data && typeof err.response.data === 'object' ? err.response.data : fallbackErr
        );
      }));
  }

  handleResponse(resp:unknown){
    const respAPI = resp as ApiResponse;
    if (respAPI.statusCode === 412){
      this.registerModal = true;
    } else {
      this.showHrModal = true;
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  register(){
    this.router.navigate(['/registration','sign_up']);
  }

}
