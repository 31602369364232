import { Component, OnInit, Output, Input, ViewEncapsulation } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'bw-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit {
  /** Show the close action icon button in the upper right corner of the modal */
  @Input() showCloseButton = false;

  /** The width of the modal: "small | medium | large"
   * The large size allows for vertical scrolling but small/medium is fixed size
   * */
  @Input() modalWidth: string;

  @Input() fullContentWidth = false;

  /** Event to close the modal */
  @Output() closeModal = new EventEmitter();

  sizeClass = 'modal-width-small';

  ngOnInit() {
    this.sizeClass = `modal-width-${this.modalWidth || 'medium'}`;
  }

  _closeModal(event: any) {
    this.closeModal.emit(event);
  }
}
