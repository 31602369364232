import { Injectable } from '@angular/core';
import { EMPTY, from, Observable } from 'rxjs';
import {
  BsCacheService,
  AwsApiWrapperService,
} from '@brightside-web/desktop/data-access/core-services';
import { ApiCacheService } from '@brightside-web/desktop/data-access/shared';
import { HttpClient } from '@angular/common/http';
import { AxiosResponse } from 'axios';
import { concatMap } from 'rxjs/operators';
import { ToastService } from '@brightside/brightside-ui-services';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(
    private apiCache:ApiCacheService,
    private http:HttpClient,
    private toastSvc:ToastService,
    private translateSvc: TranslateService,
    private bsCacheService: BsCacheService,
    protected awsAPIWrapper: AwsApiWrapperService
    ) { }

  get():Observable<EmailResponse>{
    return this.apiCache.get<EmailResponse>('api-mobile', '/client/profile');
  }

  put(email:string):Observable<{message:string}>{
    this.bsCacheService.removeItem('/client/profile');
    return from(this.awsAPIWrapper.put('api-mobile','/client/profile', {body:{email}}));
  }

  post(email:string):Observable<{message:string}>{
    this.bsCacheService.removeItem('/client/profile');
    return from(this.awsAPIWrapper.post('api-mobile','/client/profile', {body:{email}}));
  }

  verify(id:string, user_agent:string):Observable<void>{
    return this.getIp().pipe(
      concatMap(res=> {
          const payload:VerificationRequest = {
            id,
            user_agent,
            ip_address:res.ip
          }
          return from(this.awsAPIWrapper.put('api-mobile-noauth', '/client/verification', {body:payload, response:true}))
        }
      ),
      concatMap((response:AxiosResponse)=>{
        const confirmed = this.translateSvc.instant('EMAIL_CONFIRMED_WEB');
        const notConfirmed = this.translateSvc.instant('EMAIL_NOT_CONFIRMED_WEB');
          if(response.status === 200 || response.status === 202 ) {
            this.toastSvc.success(confirmed);
          }
          else {
            this.toastSvc.warn(notConfirmed);
          }
          return EMPTY; //NOTE does not call success callback, only complete callback
        }
      )
    )
  }

  getIp(): Observable<{ ip:string }> {
    return this.http.get<{ip:string}>('https://api.ipify.org/?format=json');
  }
}

export interface EmailResponse{
  company:string;
  email:string;
  verified:boolean;
}

export interface VerificationRequest{
  user_agent:string;
  ip_address:string;
  id:string;
}
