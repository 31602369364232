import { Component, OnInit } from '@angular/core';
import {
  AccountCreationResponses, AccountCreationSteps,
  FinancialAccountsCreationService,
  SocureService
} from "@brightside-web/desktop/feature/financial-accounts-core";
import {Environment} from "@micro-core/environment";
import {SimpleGlobalModalService} from "@brightside-web/desktop/data-access/core-services";
import {take} from "rxjs/operators";
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from "@angular/router";
import {FirebaseService} from "@brightside-web/desktop/data-access/shared";

interface SocureInitInterface {
  init(socure: string, divId: string, config?:any): Promise<unknown>;
  start(number: number): any;
}

interface SocureInterface {
  reset(): unknown;
  unmount(): unknown;
}

interface SocureVerificationEvent {
  eventId: string;
  status: string;
  verificationLevel: number;
  mobileNumber: string;
  key: string;
  referenceId: string;
  verifyResult: {
    referenceId: string;
  };
  documentUuid: string;
}


interface SocureInitInterface {
  init(socure: string): Promise<SocureInitInterface>;
}

declare let SocureInitializer: SocureInitInterface;
declare let Socure: SocureInterface;


@Component({
  selector: 'brightside-web-financial-core-kyc',
  templateUrl: './socure.component.html',
  styleUrls: ['./socure.component.scss'],
  providers: [SocureService]
})
export class FinancialCoreKYCComponent {

  showSpinner = true;
  showVerifyingModal = false;

  socureScript: HTMLScriptElement;
  sdkInitiated: boolean;
  showKycModal: boolean;

  config = {
    // onProgress: this.onProgress.bind(this),
    onSuccess: this.onSuccess.bind(this),
    onError: this.onError.bind(this),
    qrCodeNeeded: true
  };

  constructor(
    private financialCoreSvc: FinancialAccountsCreationService,
    private socureSvc: SocureService,
    private env: Environment,
    private simpleModalSvc: SimpleGlobalModalService,
    private router: Router,
    private route: ActivatedRoute,
    private analytics: FirebaseService
  ) {
    if (!this.route.snapshot.data['accountType']) {
      console.warn('This component should have an accountType in the ActivatedRouteSnapshot data');
    } else {
      this.financialCoreSvc.setAccountType(this.route.snapshot.data['accountType']);
    }
  }

  onProgress(progress: unknown) {}

  onSuccess(response: { documentUuid: string }) {
    this.showVerifying();
    this.financialCoreSvc.kycCheck(response.documentUuid).pipe(
      take(1)
    ).subscribe(
      kycResp => {
        this.showVerifyingModal = false;
        if (kycResp.result.code === AccountCreationResponses.KYC_VERIFIED) {
          this.router.navigate(['accounts', this.financialCoreSvc.accountType, 'dashboard'])
        }
      }
    );
  }

  onError(error: SocureVerificationEvent|null) {
    this.showKycModal = false;
    const errorString = error ? error.status : 'unknown';
    this.analytics.logEvent('kyc_failed', {code: errorString})
    this.simpleModalSvc.createGenericErrorModal('kyc_automated_review', () => {
      this.showSpinner = true;
      this.showSocureModal();
    });
  }

  showSocureModal() {
    this.showKycModal = true;
    setTimeout(()=>{
      this.socureScript = this.socureSvc.loadSocureScript();
      this.socureScript.onload = (test) => {
        this.clearSession();
        SocureInitializer.init(this.env.socure)
          .then((lib:SocureInitInterface) => {
            lib.init(this.env.socure, '#socureDiv', this.config).then(()=>{
              lib.start(1).then((response:any) => {
                  this.showSpinner = false;
                  this.sdkInitiated = true;
                },
                (error:any) => {
                  this.onError(null);
                }
              );
            })
          })
      };
    }, 10);
  }

  clearSession() {
    if (Object.keys(window).includes('Socure')) {
      Socure.reset(); // Cleans up the token stored in the browser memory.
      Socure.unmount(); // Calls the cleanup() function internally to unmount the Web SDK application from the browser and invalidate the token.
      sessionStorage.removeItem('documentVerificationToken');
      sessionStorage.removeItem('publicApiKey');
      localStorage.removeItem('devicer_id');
    }
  }

  private showVerifying() {
    this.showVerifyingModal = true;
    this.showKycModal = false;
    this.showSpinner = true;
    this.clearSession();
  }

  goHome() {
    this.router.navigate(['home']);
  }
}
