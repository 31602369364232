import { Component, OnInit } from '@angular/core';
import { EmailService } from '@brightside-web/desktop/data-access/email-verify';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@brightside/brightside-ui-services';
import { DeviceDetectorService } from 'ngx-device-detector';

const VersionTwoIndicator = 'v';


@Component({
  selector: 'brightside-web-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {
  constructor(private email:EmailService, private router:Router, private route:ActivatedRoute, private toastSvc: ToastService, private deviceService: DeviceDetectorService) { }

  ngOnInit(): void {
    const v2 = this.route.snapshot.queryParams[VersionTwoIndicator] === '2';
    const v3 = this.route.snapshot.queryParams[VersionTwoIndicator] === '3';

    if ( v3 ) {
      if ( !this.deviceService.isMobile() && !this.deviceService.isTablet() ) {
        this.router.navigate(['/registration', 'create_account']);
      }
    } else if ( v2 ) {
      if ( !this.deviceService.isMobile() && !this.deviceService.isTablet() ) {
        this.toastSvc.success("Your email has been confirmed!");
        this.router.navigate(['/registration', 'create_account']);
      }
    }
    else {
      if (this.route.snapshot.queryParams['id']) {
        this.email.verify(this.route.snapshot.queryParams['id'], window.navigator.userAgent).subscribe();
      }
      this.router.navigate(['/registration', 'create_account']);
    }
  }
}
