<!-- TODO WB-1112 can this forgot pw verify dob be combined with the signup verify dob -->
<form [formGroup]="verifyForm" (ngSubmit)="submit()">
  <bw-action-article>
    <ui-nav-exit-back header-actions
                      [trackingDetail]="{ event_name: 'verify_dob' }"></ui-nav-exit-back>

    <typography headline-semibold header-title>Verify birthday</typography>
    <div content-section [brightsideWebFb]="config.analyticsAttributes()" style="max-width: 360px">
      <bw-paragraph>We just need one more piece of information.</bw-paragraph>
      <bw-input
        label="Date of birth"
        placeholder="MM/DD/YYYY"
        formControlName="dateOfBirth"
        inputMask="00/00/0000"
        [inputError]="verifyForm.controls['dateOfBirth']?.invalid && verifyForm.controls['dateOfBirth']?.touched"
        inputErrorText="Please enter your date of birth."
        maxlength="10"
        minlength="10"
        pattern="(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d"
        [autoFocus]="true"
      ></bw-input>
    </div>
    <div footer-actions class="flex-row row-reverse">
      <button
        bw-button
        type="submit"
        [processing]="processing"
        [disabled]="verifyForm.invalid"
        [brightsideWebFb]="config.analyticsAttributes('submit')"
      >
        Continue
      </button>
    </div>
  </bw-action-article>
</form>

<brightside-web-hr-modal
  (dismissed)="showHrModal = false"
  *ngIf="showHrModal"
  [title]="'Brightside'"
  [prompt]="'We were unable to verify your identity. Please try again or contact your Financial Assistant for help.'"
  [ctaText]="'Okay'"
></brightside-web-hr-modal>
<brightside-web-sign-up-prompt
  [visible]="registerModal"
  (dismiss)="registerModal = false"
  (cta)="register()"
></brightside-web-sign-up-prompt>
