import {Injectable, TemplateRef} from '@angular/core';
import {Observable, Subject} from "rxjs";
import { BsHubService } from "../Hub/bs-hub.service";

export interface SimpleGlobalModalInterface {
  title?: string;
  subtitle?: string;
  contentKey?: string;
  eventName: string;
  ctaKey?: string;
  ctaAction?: ()=>void;
  ctaType?: 'destructive';
  secondaryCtaKey?: string;
  secondaryCtaAction?: ()=>void;
  size: 'small'|'medium'|'large';
  displayCloseButton: true|false;
  isError: true|false;
  closeRoute?: string[];
  closeAction?: ()=>void;
  headerImagePath?: string;
  templateRef?: TemplateRef<any>;
  loadingAfterCta?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SimpleGlobalModalService {

  private _modalObject = new Subject<SimpleGlobalModalInterface | null>();
  public readonly modalObject : Observable<SimpleGlobalModalInterface | null> = this._modalObject.asObservable();

  constructor(private bsHubService: BsHubService) { }

  createModal(modal: SimpleGlobalModalInterface) {
    this._modalObject.next(modal);
  }

  clearModal() {
    this._modalObject.next(null);
  }

  // todo: convert to single config object
  createGenericErrorModal(
    eventName = "network_error",
    ctaAction = () => {this.clearModal();},
    ctaKey: string = 'TRY_AGAIN',
    closeAction = () => {this.clearModal();}
  ) : void{
    this._modalObject.next({
      title: 'error_general_title'.toUpperCase(),
      contentKey: 'error_general_desc'.toUpperCase(),
      displayCloseButton: true,
      eventName: eventName,
      isError: true,
      size: "medium",
      ctaKey,
      ctaAction: ctaAction,
      closeAction: closeAction
    });
  };

  createGenericHelpModal() {
    this._modalObject.next({
      title: 'NEED_MORE_HELP_TITLE',
      contentKey: 'NEED_MORE_HELP_DESC',
      displayCloseButton: true,
      eventName: 'need_more_help',
      isError: false,
      size: "medium",
      ctaKey: 'NEED_MORE_HELP_CTA1',
      ctaAction: () => {
        this.bsHubService.dispatch('IntercomChannel', {event: 'open'});
      }
    });
  }
}
