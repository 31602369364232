import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route, Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@brightside-web/desktop/data-access/shared';
import {map, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad, CanActivate {
  constructor(private authService: AuthService, private router:Router) {}
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree  {

    return this.authService.hasCurrentUser().pipe(
      map(hasUser => {
        if (!hasUser) {
          this.authService.redirectUrl = window.location.pathname;
          if (window.location.href.indexOf('signin=true') > -1) {
            return this.router.parseUrl('/sign_in');
          } else {
            return this.router.parseUrl('/sign_up');
          }
        }
        return true;
      })
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.hasCurrentUser().pipe(
      map(hasUser => {
        if (!hasUser) {
          this.authService.redirectUrl = state.url;
          if (window.location.href.indexOf('signin=true') > -1) {
            return this.router.parseUrl('/sign_in');
          } else {
            return this.router.parseUrl('/sign_up');
          }
        }
        return true;
      })
    );
  }
}
