import { Injectable, Renderer2, Inject, inject, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Environment } from "@micro-core/environment";
import {BsAuthService} from "../Auth/bs-auth.service";
import { SalesforceModel } from "./salesforce.model";
import {Intercom} from "ng-intercom";
import {BehaviorSubject, Observable, tap} from "rxjs";
import {FeatureFlagService} from "../FeatureFlag/feature-flag.service";
import {FirebaseService, KnownFeatureFlags} from "@brightside-web/desktop/data-access/shared";
import {BsCacheService} from "../Cache/bs-cache.service";

declare let embeddedservice_bootstrap: SalesforceModel;

interface SalesforceInterface {
  organizationId: string;
  developerName: string;
  url: string;
  scrt2URL: string;
  chatScript: string;
}

@Injectable({
  providedIn: 'root'
})
export class BsChatService {

  private _chatLoaded = new BehaviorSubject<boolean>(false);
  public readonly chatLoaded: Observable<boolean> = this._chatLoaded.asObservable();

  private _chatConfig: SalesforceInterface;
  private _chatInitialized: boolean;
  private _chatScript: HTMLScriptElement;
  private renderer: Renderer2;
  alreadyLaunchedChat = false;
  private serviceLoaded: boolean;

  constructor(
    private env: Environment,
    @Inject(DOCUMENT) private document: Document,
    private rendererFactory: RendererFactory2,
    private bsAuthService: BsAuthService,
    private intercom: Intercom,
    private featureFlagSvc: FeatureFlagService,
    private bsCacheService: BsCacheService,
    private analytics: FirebaseService
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  loadChat() {
    if (this.env.chat && this.isSalesForceConfig(this.env.chat)) {
      this._chatConfig = this.env.chat;
      if (!this.alreadyLaunchedChat) this.initializeChat();
    } else {
      console.error('Invalid chat configuration');
    }
  }

  initializeChat() {
    this.alreadyLaunchedChat = true;
    this._chatScript = this.loadChatScript();
    this._chatScript.onload = () => {
      embeddedservice_bootstrap.settings.language = 'en_US';
      this.serviceLoaded = true;
      this.bsAuthService.getToken().subscribe({
        next: token => {
          if (token) {
            this.bsAuthService.fetchUserAttributes().subscribe({
              next: attributes => {
                window.addEventListener('onEmbeddedMessagingReady', () => {
                  // Send your identity token to Salesforce
                  embeddedservice_bootstrap.userVerificationAPI.setIdentityToken({
                    identityTokenType: 'JWT',
                    identityToken: token
                  });
                  // Send data to Salesforce
                  embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields({Guid: attributes.guid, Language: attributes.locale});

                  // Remove any items from the previous list that you don't want to send
                  embeddedservice_bootstrap.prechatAPI.removeHiddenPrechatFields();
                });

                embeddedservice_bootstrap.init(
                  this._chatConfig.organizationId,
                  this._chatConfig.developerName,
                  this._chatConfig.url,
                  {
                    scrt2URL: this._chatConfig.scrt2URL,
                  }
                );

                this.setUpOtherListeners();

              }
            })
          }
        }
      });

      window.addEventListener('onEmbeddedMessagingIdentityTokenExpired', () => {
        this.refreshToken();
      });

    };
  }

  refreshToken() {
    this.bsAuthService.getToken().subscribe({
      next: token => {
        if (token && this.serviceLoaded) {
          this.bsAuthService.fetchUserAttributes().subscribe({
            next: () => {
              embeddedservice_bootstrap.userVerificationAPI.setIdentityToken({
                identityTokenType: 'JWT',
                identityToken: token
              });
            }
          })
        }
      }
    });
  }

  showChat() {
    this.featureFlagSvc.getFlag(KnownFeatureFlags.ENABLESALESFORCEMESSAGING)
      .pipe(
        tap(enableSalesforce => {
          if(enableSalesforce && this.serviceLoaded) {
            embeddedservice_bootstrap.utilAPI.launchChat();
          } else {
            this.intercom.show();
          }
        })
      )
      .subscribe()
  }

  loadChatScript(): HTMLScriptElement {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = this._chatConfig.chatScript;
    this.renderer.appendChild(this.document.body, script);
    return script;
  }

  clearSession() {
    this.featureFlagSvc.getFlag(KnownFeatureFlags.ENABLESALESFORCEMESSAGING)
      .pipe(
        tap(enableSalesforce => {
          if(enableSalesforce && this.serviceLoaded) {
              embeddedservice_bootstrap.userVerificationAPI
                .clearSession()
                .finally(() => {});
          } else {
            this.intercom.shutdown();
          }
        })
      )
      .subscribe()
  }

  private isSalesForceConfig(chat: any): chat is SalesforceInterface {
    return (
      chat &&
      typeof chat.organizationId === 'string' &&
      typeof chat.developerName === 'string' &&
      typeof chat.url === 'string' &&
      typeof chat.scrt2URL === 'string' &&
      typeof chat.chatScript === 'string'
    );
  }

  private setUpOtherListeners() {
    this._chatLoaded.next(true);
    window.addEventListener('onEmbeddedMessagingWindowMinimized',() => {
      this.analytics.logEvent('chat_dismissed', {}, true);
    });

    window.addEventListener('onEmbeddedMessagingWindowMaximized',() => {
      this.setUpClickWatcher();
      this.bsCacheService.getItem('fromOnboarding').then(
        fromOnboarding => {
          if (fromOnboarding === true) {
            this.analytics.logEvent('chat_shown', { source: 'onboarding' }, true);
            this.bsCacheService.removeItem('fromOnboarding');
          } else {
            this.analytics.logEvent('chat_shown', { source: 'client' }, true);
          }
        }
      )
    });
  }

  private setUpClickWatcher() {
    window.addEventListener('message', (event) => {
      // console.log('ken is here listening to messages', event);

      // Handle the message as described above
    });
    // const maxRetryAttempts = 5;
    // let retriesCompleted = 0;
    // console.log('ken is in setUpClickWatcher');
    // const tryToConnectWatcher = () => {
    //   console.log('ken is in tryToConnectWatcher');
    //   setTimeout(() => {
    //     try {
    //       console.log('ken is in tryToConnectWatcher timeout try', retriesCompleted);
    //       const embeddedMessenger = document.getElementById('embeddedMessagingFrame') as HTMLIFrameElement;
    //       const contentWindow = embeddedMessenger.contentWindow;
    //       if (contentWindow) {
    //         contentWindow.removeEventListener(`click`, () => {});
    //         contentWindow.addEventListener(`click`, (e: any) => {
    //           const origin = e.target.closest('a') as HTMLAnchorElement;
    //           if (origin && origin.href.includes('gobrightside://')) {
    //             e.preventDefault();
    //             console.log('ken has clicked');
    //             // this.internalLinkRouting.routeToLink(origin.href);
    //           }
    //         });
    //       }
    //       console.log('ken here', embeddedMessenger, contentWindow);
    //     } catch (e) {
    //       console.log('ken is in tryToConnectWatcher timeout catch', e);
    //       if (maxRetryAttempts > 0 && maxRetryAttempts < retriesCompleted) {
    //         return;
    //       }
    //
    //       setTimeout(() => {
    //         tryToConnectWatcher();
    //         retriesCompleted++;
    //       }, 1000);
    //     }
    //
    //   }, 1000);
    // }
    // tryToConnectWatcher();
  }
}
